var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "chartView" }, [
      _c(
        "div",
        { staticClass: "chartAttendace" },
        [
          _vm.loaded
            ? _c("ChartLine", {
                attrs: {
                  chartData: _vm.dataConllection1.chartData,
                  options: _vm.dataConllection1.options,
                  height: 250,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "chartFees" },
      [
        _vm.loaded
          ? _c("ChartBar", {
              attrs: {
                checkLable: true,
                chartData: _vm.dataConllection2.chartData,
                options: _vm.dataConllection2.options,
                height: 250,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("hr"),
    _c(
      "div",
      [
        _c(
          "div",
          {
            staticStyle: {
              "text-align": "center",
              "font-weight": "bold",
              "font-family": "arial",
            },
          },
          [_vm._v("\n      Thông tin trường\n    ")]
        ),
        _c(
          "el-form",
          {
            ref: "schoolData",
            attrs: {
              "label-width": "150px",
              model: _vm.schoolData,
              "label-position": "left",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Địa chỉ", prop: "schoolAddress" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "Nhập địa chỉ" },
                  model: {
                    value: _vm.schoolData.schoolAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.schoolData, "schoolAddress", $$v)
                    },
                    expression: "schoolData.schoolAddress",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "content-2",
                staticStyle: { display: "flex", "flex-direction": "row" },
              },
              [
                _c(
                  "div",
                  { staticClass: "avt-info", staticStyle: { width: "80%" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Điện thoại", prop: "schoolPhone" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Nhập số điện thoại" },
                          model: {
                            value: _vm.schoolData.schoolPhone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.schoolData,
                                "schoolPhone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "schoolData.schoolPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Ảnh bìa" } },
                      [
                        _vm.schoolData.schoolAvatar != null
                          ? _c("el-image", {
                              staticStyle: { width: "150px", height: "150px" },
                              attrs: {
                                src: _vm.schoolData.schoolAvatar,
                                fit: "fill",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            staticClass: "avatar-uploader",
                            class: { hideUpload: !_vm.showUpload },
                            staticStyle: { position: "absolute", top: "0%" },
                            attrs: {
                              "show-file-list": true,
                              "list-type": "picture-card",
                              "on-change": _vm.handleChangeUpload,
                              "on-remove": _vm.handleRemove,
                              "auto-upload": false,
                              limit: "1",
                            },
                          },
                          [
                            _vm.schoolData.schoolAvatar != null
                              ? _c("span", [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                      staticStyle: { "font-size": "20px" },
                                    },
                                    [_vm._v("Cập nhật ảnh")]
                                  ),
                                ])
                              : _c("span", [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                      staticStyle: { "font-size": "20px" },
                                    },
                                    [_vm._v("Thêm ảnh")]
                                  ),
                                ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "concat-detail" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-below",
                        attrs: { label: "Email", prop: "schoolEmail" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Nhập email" },
                          model: {
                            value: _vm.schoolData.schoolEmail,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.schoolData,
                                "schoolEmail",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "schoolData.schoolEmail",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-below",
                        attrs: { label: "Website", prop: "schoolWebsite" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Nhập website" },
                          model: {
                            value: _vm.schoolData.schoolWebsite,
                            callback: function ($$v) {
                              _vm.$set(_vm.schoolData, "schoolWebsite", $$v)
                            },
                            expression: "schoolData.schoolWebsite",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-below",
                        attrs: { label: "Liên hệ 1", prop: "contactPhone1" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Số điện thoại 1" },
                          model: {
                            value: _vm.schoolData.contactPhone1,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.schoolData,
                                "contactPhone1",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "schoolData.contactPhone1",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-below",
                        attrs: { label: "Tên", prop: "contactName1" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Nhập nội dung" },
                          model: {
                            value: _vm.schoolData.contactName1,
                            callback: function ($$v) {
                              _vm.$set(_vm.schoolData, "contactName1", $$v)
                            },
                            expression: "schoolData.contactName1",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-below",
                        attrs: { label: "Liên hệ 2", prop: "contactPhone2" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Số điện thoại 2" },
                          model: {
                            value: _vm.schoolData.contactPhone2,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.schoolData,
                                "contactPhone2",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "schoolData.contactPhone2",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-below",
                        attrs: { label: "Tên", prop: "contactName2" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Nhập nội dung" },
                          model: {
                            value: _vm.schoolData.contactName2,
                            callback: function ($$v) {
                              _vm.$set(_vm.schoolData, "contactName2", $$v)
                            },
                            expression: "schoolData.contactName2",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-below",
                        attrs: { label: "Liên hệ 3", prop: "contactPhone3" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Số điện thoại 3" },
                          model: {
                            value: _vm.schoolData.contactPhone3,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.schoolData,
                                "contactPhone3",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "schoolData.contactPhone3",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "input-below",
                        attrs: { label: "Tên", prop: "contactName3" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Nhập nội dung" },
                          model: {
                            value: _vm.schoolData.contactName3,
                            callback: function ($$v) {
                              _vm.$set(_vm.schoolData, "contactName3", $$v)
                            },
                            expression: "schoolData.contactName3",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }